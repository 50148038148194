
<template>
  <div class="hello">
    <img class="imgsw" :src="imgs" alt="" />
    <!-- <div class="swiper-container gallery-thumbs">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in arr1"
          :key="index"
          @click="gallery(item)"
          class="swiper-slide"
          :style="'background-image: url(' + item.img_url + ');'"
        ></div>
      </div>
    </div> -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
          <img class="swiper-slide"
          v-for="(item, index) in arr1"
          :key="index"
          @click="gallery(item)"
          :src="item.img_url" alt="" />
      </div>
    </div>
  </div>
</template>
 
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
// import "swiper/dist/js/swiper.min.js";
// import { setTimeout } from "timers";
export default {
  name: "HelloWorld",
  data() {
    return {
      swiper1: "",
      swiper2: "",
      imgs: "",
      arr1: [],
    };
  },
  mounted() {
    const that =this
      that.arr1 = [
        {
          img_url:
            "https://ppyos.xijiuyou.com/202201/rpskin/paxl-big.jpg",
        },
        {
          img_url:
            "https://ppyos.xijiuyou.com/202201/rpskin/qianshou-big.jpg",
        },
        {
          img_url:
            "https://ppyos.xijiuyou.com/202201/rpskin/paxl-big.jpg",
        },
        {
          img_url:
            "https://ppyos.xijiuyou.com/202201/rpskin/qianshou-big.jpg",
        },
        {
          img_url:
            "https://ppyos.xijiuyou.com/202201/rpskin/paxl-big.jpg",
        },
      ]
      setTimeout(()=>{
        that.swiper1 = new Swiper('.swiper-container', {
      slidesPerView: 4,
      spaceBetween: 30,
      autoplay: {
        delay: 1000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      loop:true,
      centeredSlides: true,
      on: {
        slideChangeTransitionEnd: function(){
          that.imgs = that.arr1[this.activeIndex-4].img_url
        },
      }
    });
      },10)
      
    this.imgs = this.arr1[0].img_url;
 
    
    
    // var galleryTop =
    //  this.swiper2 = new Swiper('.gallery-top', {
    //   spaceBetween: 10,
    //   loopedSlides: 5, //looped slides should be the same
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    // });
    // this.swiper2.controller.control = this.swiper1;//Swiper1控制Swiper2，需要在Swiper2初始化后
    // this.swiper1.controller.control = this.swiper2;
  },
  methods: {
    /*鼠标移入停止轮播,5秒后继续轮播，鼠标离开 继续轮播*/
    gallery(item) {
      this.imgs = item.img_url;
      const that = this;
      console.log(that.swiper1);
      that.swiper1.slideTo(that.swiper1.clickedIndex, 1000, false);
      setTimeout(()=>{
        that.swiper1.autoplay.start();
      },100)
    },
  },
};
</script>
<style scoped>
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.imgsw{
  width: 30%;
}
.swiper-slide{
  padding: 5px;
}
.swiper-slide img{
  width: 100%;
}.swiper-slide-active{
  border:5px solid #000000;
  border-radius: 10px;
  padding: 0;
}
</style>
